/********** Template CSS **********/
:root {
  --primary: #ec001b;
  --light: #F1F8FF;
  --dark: #0F172B;
}

.ff-secondary {
  font-family: 'Pacifico', cursive;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}


/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  font-size: 15px;
  color: var(--light) !important;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
  transition: .5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 0;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

.navbar-dark .navbar-brand img {
  max-height: 60px;
  transition: .5s;
}

.sticky-top.navbar-dark .navbar-brand img {
  max-height: 45px;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-dark {
      position: relative;
  }

  .navbar-dark .navbar-collapse {
      margin-top: 15px;
      border-top: 1px solid rgba(255, 255, 255, .1)
  }

  .navbar-dark .navbar-nav .nav-link,
  .sticky-top.navbar-dark .navbar-nav .nav-link {
      padding: 10px 0;
      margin-left: 0;
  }

  .navbar-dark .navbar-brand img {
      max-height: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-dark {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 999;
      background: transparent !important;
  }
  
  .sticky-top.navbar-dark {
      position: fixed;
      background: var(--dark) !important;
  }
}


/*** Hero Header ***/
.hero-section-features {
  max-height: 85vh;
  min-height: 65vh;
  background-color: rgba(38,38,38,.8);
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 40px;
  padding-bottom: 70px;
  overflow: visible;
}


.w-background-video {
    height: 500px;
    color: #fff;
    position: relative;
    overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
}
video{
  vertical-align: baseline;
    display: inline-block;
}
.container-41 {
  max-width: 1140px;
  flex-direction: row;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  display: flex;
}

.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}
.feature-content-copy{
z-index: 1;
width: 100%;
margin-top: 40px;
position: relative;
}
.herotext-white {
  color: #fff;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 52px;
  font-weight: 900;
  line-height: 70px;
  display: inline;
}
h1 {
  margin-top: 20px;
  font-size: 38px;
  line-height: 44px;
}
.herotext-yellow {
  color: var(--primary);
  font-size: 62px;
  line-height: 70px;
  display: inline;
}
p.slideInLeft{
  font-size: large;
}
.subtext.white {
  max-width: 560px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 24px;
}

.subtext {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 18px;
  text-align: justify;
}


.div-block-155 {
    margin-top: 20px;
    position: relative;
}
.btn-yellow {
  height: auto;
  text-align: center;
  object-fit: fill;
  flex-wrap: nowrap;
  margin-top: 0;
  margin-left: 0;
  margin-right: 20px;
  padding: 20px 40px;
  font-family: Ubuntu,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.hero-header {
  background: linear-gradient(rgba(15, 23, 43, .7), rgba(15, 23, 43, .7)), url(./img/bg-hero.jpg);
  
}
@media  (max-width: 992px) {
  .hero-section-features{
    margin-top: 0px;
    padding-left: 20px;
    min-height: 20vh;
  }
  
  .herotext-white {
    font-size: 40px;
    font-weight: 900;
    line-height: 42px;
  }
  h1 {
    font-size: 40px;
    line-height: 42px;
  }
  .herotext-yellow {
    font-size: 40px;
    line-height: 42px;
    display: inline;
  }
  .div-block-155 {
      margin-top: 20px;
  }
  .subtext.white {
    font-size: 18px;
  }
  
  .btn-yellow {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
  }
  
  
}

.hero-header img {
  animation: imgRotate 30s linear infinite;
}

@keyframes imgRotate { 
  0% {
    transform: rotate(0deg);
  }
  10%{
    transform: rotate(15deg);
  }
  20%{
    transform: rotate(30deg);
  }
  30%{
    transform: rotate(45deg);
  }
  40%{
    transform: rotate(30deg);
  }
  50%{
    transform: rotate(15deg);
  }
  60%
  {
    transform: rotate(0deg);
  }
  70%{
    transform: rotate(-20deg);
  }
  80%{
    transform: rotate(-35deg);
  }
  90%
  {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }

}

.breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, .5);
}


/*** Section Title ***/
.section-title {
  position: relative;
  display: inline-block;
}

.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--primary);
}

.section-title.text-start::before,
.section-title.text-end::after {
  display: none;
}


/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transition: .5s;
}

.service-item:hover {
  background: var(--primary);
}

.service-item * {
  transition: .5s;
}

.service-item:hover * {
  color: var(--light) !important;
}


/*** Food Menu ***/
.nav-pills .nav-item .active {
  border-bottom: 2px solid var(--primary);
}


/*** Youtube Video ***/
.video {
  position: relative;
  height: 100%;
  min-height: 500px;
  background: linear-gradient(rgba(15, 23, 43, .1), rgba(15, 23, 43, .1)), url(./img/contact.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
  }

  100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
  }
}

#videoModal {
  z-index: 99999;
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #FFFFFF;
  background: #000000;
  opacity: 1;
}


/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  height: calc(100% - 38px);
  transition: .5s;
}

.team-item img {
  transition: .5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item:hover {
  height: 100%;
}

.team-item .btn {
  border-radius: 38px 38px 0 0;
}


/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: var(--light) !important;
}


.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
}

.myCarousel {
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: var(--primary) !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: var(--primary) !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}

/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #FFFFFF;
  border-radius: 35px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.menu-list ul li {
  list-style: none;
}